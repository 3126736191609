import { APP_DEFAULT_TIMEZONE } from '@tapestry/shared/constants';
import { dateTime } from '@tapestry/shared/utils';
import {
  DateObject,
  ShiftingPeriod,
  getPeriodFromDates,
} from './get-period-from-dates';
import { HeartbeatComparisonPeriod } from '@tapestry/types';
import { ManipulateType } from 'dayjs';

const getUnit = (
  period: HeartbeatComparisonPeriod | ShiftingPeriod
): { number: number; unit: ManipulateType | undefined } => {
  switch (period) {
    case 'userDefined':
      return { number: 0, unit: undefined };

    case 'year': // * default shifting period
      return { number: 52, unit: 'week' };

    case 'calendarYear':
    case 'quarter':
    case 'month':
      return { number: 1, unit: 'year' };

    case 'week':
      return { number: 1, unit: 'week' };

    default:
      throw new Error(
        'getUnit(): provide a period to substract (i.e. "year",....)'
      );
  }
};

export const getCompareWithDates = (
  dates: DateObject,
  comparisonPeriod = HeartbeatComparisonPeriod.Year,
  currentShopTimezone = APP_DEFAULT_TIMEZONE
) => {
  if (!dates) throw new Error('Please provide a dates');

  let datePeriod: HeartbeatComparisonPeriod | ShiftingPeriod;

  if (comparisonPeriod === 'userDefined') {
    datePeriod = comparisonPeriod;
  } else {
    datePeriod = getPeriodFromDates(dates) || comparisonPeriod;
  }

  const { number, unit } = getUnit(datePeriod);

  // TAP-1164: if the current lookup range is the current week,
  // the comparison range should end at the current date
  // this will prevent comparing a partial week with a full week

  const currentDateAtShopTimeZone = dateTime.now().tz(currentShopTimezone);
  const isInCurrentPeriod = dateTime.checkIsBetween(
    currentDateAtShopTimeZone,
    dates.startDate,
    dates.endDate
  );

  const _endDate = dateTime
    .convertToTimezone(
      isInCurrentPeriod ? currentDateAtShopTimeZone.format() : dates.endDate,
      currentShopTimezone
    )
    .subtract(number, unit);

  const formattedEndDateCompare = !isInCurrentPeriod
    ? _endDate.endOf('day').format()
    : _endDate.format();

  const compareWithDates = {
    startDateCompare: dateTime
      .convertToTimezone(dates.startDate, currentShopTimezone)
      .subtract(number, unit)
      .format(),
    endDateCompare: formattedEndDateCompare,
  };

  return compareWithDates;
};

import { ICustomHeartbeatListTypeEnum } from '@tapestry/shared/graphql';
import {
  HearbeatComparisonKey,
  HeartbeatComparisonPeriod,
} from '@tapestry/types';

export enum HearBeatComparisonKey {
  Period = 'period',
  Measure = 'measure',
  ShopId = 'shopId',
  GroupId = 'groupId',
  ListId = 'listId',
}

export const HeartbeatComparisonPeriodOptions = [
  {
    value: HeartbeatComparisonPeriod.Year,
    label: 'vs Last Year',
  },
  {
    value: HeartbeatComparisonPeriod.Week,
    label: 'vs Last Week',
  },
  {
    value: HeartbeatComparisonPeriod.UserDefined,
    label: 'Select Dates...',
  },
];

export const DEFAULT_COMPARISON_VARIABLE = {
  key: HearbeatComparisonKey.Period,
  value: HeartbeatComparisonPeriod.Week as string,
};

export const DEFAULT_GET_LISTS_COMPARE_VARIABLES = {
  listType: ICustomHeartbeatListTypeEnum.Personal,
  limit: 1000,
  page: 1,
};

import React, { useMemo, useEffect, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Stack } from '@tapestry/weave';
import {
  OnboardingCarousel,
  AnnouncementBar,
  WidgetBar,
  WidgetBarButton,
  InviteARetailerBanner,
} from '@tapestry/shared/components';
import {
  MeasuresSliderMenu,
  HeartbeatTabChart,
  HeartbeatPrimaryChart,
  ExportButton,
  HeartbeatDateRangeSelector,
  MetricOptionsSelector,
  AlertCreateModal,
  AlertListModal,
  ComparisonModal,
  ComparisonButton,
  ShopThreadHeader,
  ThreadHeader,
  ExportModal,
  FilterModal,
  TopShopsVisualisation,
  SupplierInsightsModalLazy,
  HorizontalChartLazy,
  CollectionsVisualizationLazy,
  HeatmapChartLazy,
  ListVisualisation,
} from './components';
import {
  BarcodeScannerIcon,
  ExportIcon,
  GraphMetricIcon,
  PlusIcon,
  SlidersIcon,
} from '@tapestry/shared/icons';
import { useAppMediaQuery } from '@tapestry/weave';
import {
  DEFAULT_GET_LISTS_COMPARE_VARIABLES,
  getHeartbeatAnnouncement,
  HeartbeatVisualizationQueriesBaseVariables,
  UNALLOWED_SCOPE_TYPE,
} from './constants';
import {
  DEMO_SHOPS_IDS,
  METRICS,
  RETAIL_SLIDE_CONTENT,
  ROUTE_PATHS,
} from '@tapestry/shared/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useGetSimpleThreadLazyQuery,
  useShopSelector,
} from '@tapestry/shared/graphql';
import {
  JsonParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useToast, useUIContext } from '@tapestry/shared/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  useProfile,
  useInitialRenderOnIntersection,
  useActiveShop,
  useEffectAfterMount,
  useModal,
  ClientOnly,
  useUpdateUserSettings,
} from '@tapestry/shared/hooks';
import {
  getActiveShopFromGroupedShop as getActiveScopeFromGroups,
  RecommendedTasks,
} from '@tapestry/shared/components';
import {
  Visualization,
  MeasureSlug,
  THREAD_TYPE,
  Twist,
  UserSetting,
  HeartbeatAppletFilters,
  HeartbeatComparisonPeriod,
  HearbeatComparisonKey,
  IsoStringWithTZOffset,
} from '@tapestry/types';
import qs from 'query-string';
import {
  getDimensionsLabelFromActiveMetric,
  VisualizationPermissionGate,
  getTitle,
  shouldItBeExportable,
  getScopeId,
  getComparisonResultLabels,
  getComparisonQueryVariable,
  mapActiveFiltersToQueryFilters,
  removeFaultyParam,
  getDefaultFilters,
  getDefaultQueryParams,
  decideIfShouldShowAnnouncement,
  getDefaultComparison,
} from './utils';
import {
  dateTime,
  deriveCurrentThreadTypeFromQueryString,
  getCurrentAppInfo,
  getPageTitle,
  getTwistByKey,
  trackEvent,
} from '@tapestry/shared/utils';
import {
  SellWellWithWidget,
  SupplierWidget,
} from '@tapestry/shared/visualisation';
import {
  PersonaVisual,
  PostcodeVisualisation,
  StaffVisualisation,
} from '@poc/visualisation';
import { useRouter } from 'next/router';
import { ProductVariantVisualisation } from './components/ProductVariantVisualisation';
import { useGetLists } from '@tapestry/shared/applets/lists';
import Head from 'next/head';

const { isSupplyApp, appInfo } = getCurrentAppInfo();
const DAYS_IN_WEEK = 7;

export const Heartbeat: FC = () => {
  const router = useRouter();
  const [profile, setProfile] = useProfile();
  const activeShop = useActiveShop();
  const currentShopTimezone = getTwistByKey(Twist.Timezone, activeShop)?.value;
  const { startDate: defaultStart, endDate: defaultEnd } =
    dateTime.getCurrentIsoWeek(
      profile?.active_shop_scope?.timezone || currentShopTimezone
    );
  const heartbeatUserDefaultComparison = getDefaultComparison(profile);

  const {
    personasVisualisation: hasPersonasVisualisationFlag,
    postcodeVisualisation: hasPostcodeVisualisationFlag,
    staffVisualisation: hasStaffVisualisationFlag,
    recommendedTasks: hasRecommendedTasksFlag,
    productVariantsVisualisation: hasProductVariantsVisualisationFlag,
  } = useFlags();

  // ! Update the Confluence docs if you add/remove any qp
  // https://tapestry-ai.atlassian.net/wiki/spaces/TAP/pages/657063937/Heartbeat+Query+Parameters
  const [urlQueryState, setUrlQueryState] = useQueryParams(
    getDefaultQueryParams({
      defaultStart,
      defaultEnd,
      defaultComparison: heartbeatUserDefaultComparison,
    })
  );

  const {
    shopId,
    groupId,
    slug: measureSlug,
    mVar: measureVariant,
    startDate,
    endDate,
    tagId,
    returnTo,
    supplierId,
    listId,
    comparison: comparisonVariable,
  } = urlQueryState;
  const { type: activeThreadKind, id: activeThreadId } =
    deriveCurrentThreadTypeFromQueryString(urlQueryState);
  // ! ANYTHING THAT DEPENDS ON THE URL CREATES REHYDRATION PROBLEM BETWEEN SSG AND REHYDRATION

  const [getSimpleThread, { data: activeThread }] = useGetSimpleThreadLazyQuery(
    {
      variables: { uid: activeThreadId || null },
      onError: () => {
        addToast({ type: 'error', content: 'Item not found' });

        if (returnTo) {
          router.push(returnTo);
        } else {
          // clears the faulty query param or reset to default
          setUrlQueryState(
            (currentQS) => removeFaultyParam(currentQS, activeThreadId),
            'replaceIn'
          );
        }
      },
    }
  );

  const comparisonModal = useModal();
  const exportModal = useModal();
  const filterModal = useModal();
  const createAlertModal = useModal();
  const alertListModal = useModal();
  const supplierInsightsModal = useModal();

  const isGroupView = !!groupId;
  const { isPhone } = useAppMediaQuery();
  const [{ threadTypeThemeColors, shouldShowAnnouncement }, dispatchUI] =
    useUIContext();
  const { addToast } = useToast();
  const heartbeatOnboardingSlides = useOverlayTriggerState({});
  const [inViewProductRef, hasMountedProductOnceBefore] =
    useInitialRenderOnIntersection();
  const [inViewCategoryRef, hasMountedCategoryOnceBefore] =
    useInitialRenderOnIntersection();
  const [inViewSpacesRef, hasMountedSpacesOnceBefore] =
    useInitialRenderOnIntersection();
  const [inViewCollectionsRef, hasMountedCollectionsOnceBefore] =
    useInitialRenderOnIntersection();
  const [inViewHeatmapRef, hasMountedHeatmapBefore] =
    useInitialRenderOnIntersection();
  const [inViewStaffVizRef, hasMountedStaffVizBefore] =
    useInitialRenderOnIntersection();
  const [inViewTopSuppliersRef, hasMountedTopSuppliersBefore] =
    useInitialRenderOnIntersection();
  const [inViewListsRef, hasMountedListBefore] =
    useInitialRenderOnIntersection();

  const [activeFilters, setActiveFilters] = useQueryParam<
    HeartbeatAppletFilters | undefined
  >('filters', withDefault(JsonParam, getDefaultFilters(profile)));
  const activeFiltersCount = Object.keys(activeFilters || {}).length;

  const queryFilters = useMemo(
    () => ({
      [groupId ? 'groupId' : 'shopId']: groupId ? groupId : shopId,
      supplierId,
      listId,
      ...mapActiveFiltersToQueryFilters(activeFilters),
    }),
    [groupId, shopId, listId, supplierId, activeFilters]
  );

  // [DEMO] 06-06-2020: Add useShopSelector to derive active shop for inbox component
  // TOFIX: once confirm that the inbox requires the shop information
  // remove useShopSelector from AlertCreateModal, ComparisonModal, HearbeatHeader, HeartbeatPrimaryChart
  // then pass the shopInformation from this step to those components
  const { data: stores } = useShopSelector();
  const {
    data: { lists },
  } = useGetLists({
    variables: DEFAULT_GET_LISTS_COMPARE_VARIABLES,
  });

  const activeShopOrGroup = getActiveScopeFromGroups(
    stores?.shopSelector?.groups || [],
    shopId,
    groupId
  );

  const currentMeasure =
    METRICS.find((metric) => metric.key === measureSlug) || null;

  const visualizationQueriesBaseVariables: HeartbeatVisualizationQueriesBaseVariables =
    useMemo(
      () => ({
        startDate,
        endDate,
        measure: measureVariant || measureSlug,
        filters: JSON.stringify(queryFilters),
        scopeType: UNALLOWED_SCOPE_TYPE.includes(activeThreadKind)
          ? ''
          : activeThreadKind,
        scopeId: UNALLOWED_SCOPE_TYPE.includes(activeThreadKind)
          ? null
          : getScopeId(activeThreadKind, activeThreadId, tagId),
      }),
      [
        startDate,
        endDate,
        activeThreadKind,
        activeThreadId,
        queryFilters,
        measureSlug,
        measureVariant,
        tagId,
      ]
    );

  const productChartVars = useMemo(
    () => ({
      ...visualizationQueriesBaseVariables,
      threadType: 'product',
    }),
    [visualizationQueriesBaseVariables]
  );
  const categoryChartVars = useMemo(
    () => ({
      ...visualizationQueriesBaseVariables,
      threadType: 'category',
    }),
    [visualizationQueriesBaseVariables]
  );
  const departmentChartVars = useMemo(
    () => ({
      ...visualizationQueriesBaseVariables,
      threadType: 'department',
    }),
    [visualizationQueriesBaseVariables]
  );
  const spaceChartVars = useMemo(
    () => ({
      ...visualizationQueriesBaseVariables,
      threadType: 'space',
    }),
    [visualizationQueriesBaseVariables]
  );

  const {
    measureAlert: hasMeasureAlertFlag,
    tempRetailHeartbeatSupplierInsights: hasTempRetailHeartbeatSupplierInsights,
    scanner: hasScannerPermission,
    tempHearbeatComprehensiveReportDownload:
      hasTempHearbeatComprehensiveReportDownload,
    listVisualisation: hasListVisualisationFlag,
    tasksApplet: tasksAppletFlag,
  } = useFlags();

  const ActiveMeasureTitle = currentMeasure?.title || 'N/A';
  const isExportable = shouldItBeExportable(currentMeasure, activeThreadKind);
  const {
    topDepartmentLabel,
    topCategoriesLabel,
    topProductsLabel,
    betsTimesLabel,
    topSpacesLabel,
  } = getDimensionsLabelFromActiveMetric(currentMeasure);
  const threadTitle = getTitle(activeThreadKind, urlQueryState, activeThread);

  const handleStringifyCurrentParamsWithOptionalAdd = (
    param: { [name: string]: string | number } = {}
  ) => {
    return qs.stringify({
      shopId,
      groupId,
      slug: measureSlug,
      mVar: measureVariant,
      startDate,
      endDate,
      returnTo,
      comparison: JSON.stringify(comparisonVariable),
      ...param,
    });
  };

  const handleFinishRetailOnboarding = () => {
    /**
     * The demo shops/groups belongs to one user account. This user account is supposed to be used by prospective new user to test the platform.
     * The leadership wants each person (as in the physical world) to see the onboarding but obviously not spam the ones that have seen it already.
     * Hence we have decided to currently store that flag in `localStorage` for the demo shops as it only browser persistent
     */
    const isDemoScope = groupId
      ? DEMO_SHOPS_IDS.includes(groupId)
      : DEMO_SHOPS_IDS.includes(shopId || '');

    if (isDemoScope && typeof window !== 'undefined') {
      localStorage.setItem('demoShopUserHasSeenOnboarding', String(true));
    } else {
      setProfile({
        variables: {
          onboarded_status: { ...profile?.onboarded_status, welcome: true },
        },
      });
    }

    dispatchUI({ type: 'UPDATE_SHOULD_SHOW_ANNOUNCEMENT', payload: false });
    heartbeatOnboardingSlides.close();
  };

  const shopAnnouncementBanner = getTwistByKey(
    Twist.OnboardingImage,
    activeShop
  )?.value;

  const announcement = getHeartbeatAnnouncement({
    onAction: heartbeatOnboardingSlides.open,
    onClose: handleFinishRetailOnboarding,
    shopAnnouncementBanner,
  });

  const onDateSelection = (dates: { startDate: string; endDate: string }) => {
    const periodLength =
      dateTime.diff(dates.startDate, dates.endDate, 'days') + 1;

    trackEvent({
      event: 'DateSelectionEvent',
      category: 'date_selection',
      action: 'date_interval_conversion',
      label: periodLength.toString(),
    });

    const shouldResetComparison =
      periodLength > DAYS_IN_WEEK ||
      // * 3rd April 2024 - currently, it is required that the comparison start date falls on the same day of the week than the main period. Hence, if the main period start date changes we must reset the comparison period
      comparisonVariable.value === HeartbeatComparisonPeriod.UserDefined;

    if (shouldResetComparison) {
      setUrlQueryState({
        ...dates,
        comparison: {
          key: HearbeatComparisonKey.Period,
          value: HeartbeatComparisonPeriod.Year,
        },
      });
    } else {
      setUrlQueryState(dates);
    }
  };

  const handleDateRangeOnReset = React.useMemo(() => {
    if (
      urlQueryState.startDate === defaultStart &&
      urlQueryState.endDate === defaultEnd
    ) {
      return;
    }

    return () => {
      // * 3rd April 2024 - currently, it is required that the comparison start date falls on the same day of the week than the main period. Hence, if the main period start date changes we must reset the comparison period
      const shouldResetComparison =
        comparisonVariable.value === HeartbeatComparisonPeriod.UserDefined;

      setUrlQueryState((last) => ({
        ...last,
        startDate: defaultStart,
        endDate: defaultEnd,
        comparison: shouldResetComparison
          ? heartbeatUserDefaultComparison
          : last.comparison,
      }));
    };
  }, [
    defaultEnd,
    defaultStart,
    setUrlQueryState,
    urlQueryState,
    heartbeatUserDefaultComparison,
    comparisonVariable.value,
  ]);

  /**************************************************************
   * Comparison
   *************************************************************/
  const handleComparisonOnClick = () => {
    trackEvent({
      event: 'HeartbeatComparisonEvent',
      category: 'heartbeat_comparison',
      action: 'open_modal_clicked',
    });

    comparisonModal.open();
  };

  const handleComparisonOnReset = React.useMemo(() => {
    if (
      comparisonVariable.key === heartbeatUserDefaultComparison.key &&
      comparisonVariable.value === heartbeatUserDefaultComparison.value
    ) {
      return;
    }

    return () => {
      setUrlQueryState({ comparison: heartbeatUserDefaultComparison });
    };
  }, [comparisonVariable, heartbeatUserDefaultComparison, setUrlQueryState]);

  const comparisonQueryVariable = React.useMemo(
    () =>
      getComparisonQueryVariable(
        comparisonVariable,
        startDate as IsoStringWithTZOffset,
        endDate as IsoStringWithTZOffset,
        currentShopTimezone
      ),
    [comparisonVariable, startDate, endDate, currentShopTimezone]
  );

  const comparisonLabels = getComparisonResultLabels(
    comparisonVariable,
    dateTime.format(comparisonQueryVariable.startDateCompare, 'DD/MM/YY'),
    dateTime.format(comparisonQueryVariable.endDateCompare, 'DD/MM/YY'),
    lists,
    stores?.shopSelector?.allShops,
    stores?.shopSelector?.groups
  );

  /**************************************************************
   * Filter modal handler
   *************************************************************/
  const updateUserSettings = useUpdateUserSettings();

  const handleSetFiltersAsDefault = (
    filters: HeartbeatAppletFilters | null
  ) => {
    updateUserSettings(UserSetting.applet_analytic_filters, filters);
  };

  const handleOnFilterReset = () => {
    setActiveFilters(undefined);
    handleSetFiltersAsDefault(null);
  };

  /**************************************************************
   * Header elipsis menu
   *************************************************************/
  const mobileEllipsisItems = isPhone
    ? [
        {
          label: 'Compare',
          subLabel: 'Add a comparison to your current view',
          icon: <GraphMetricIcon />,
          action: handleComparisonOnClick,
        },
        {
          label: 'Filter',
          subLabel: 'Add filters to your current view',
          icon: <SlidersIcon />,
          action: filterModal.open,
        },
        {
          label: 'Export',
          subLabel: 'Export data from the current view',
          icon: <ExportIcon fillColor="currentColor" />,
          action: exportModal.open,
        },
      ]
    : [];

  const pageTitle = getPageTitle(
    appInfo.title,
    'Analyse',
    activeThreadKind,
    threadTitle ?? ''
  );

  /*******************************
   * Hooks
   ****************/
  useEffect(() => {
    if (
      activeThreadKind === THREAD_TYPE.SHOP ||
      activeThreadKind === THREAD_TYPE.COLLECTION ||
      activeThreadKind === THREAD_TYPE.LIST ||
      !activeThreadId
    )
      return;

    getSimpleThread({ variables: { uid: activeThreadId } });
  }, [activeThreadId, activeThreadKind, getSimpleThread]);

  //TODO[medium]: Can this be remove now that we have separate pages for each thread type? 6th Feb 2024
  useEffect(
    function updateThemeColorOnThreadChange() {
      dispatchUI({
        type: 'UPDATE_THREAD_THEME_COLORS',
        payload: activeThreadKind,
      });
    },
    [activeThreadKind, dispatchUI]
  );

  useEffectAfterMount(
    function resetComparisonVariable() {
      setUrlQueryState({ comparison: heartbeatUserDefaultComparison });
    },
    [activeThreadKind]
  );

  return (
    <ClientOnly>
      <Head>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={`Analyse ${activeThreadKind} - ${threadTitle}`}
        />
      </Head>
      <div className="min-h-screen bg-gray-100 pb-16">
        {activeThreadKind === THREAD_TYPE.SHOP ? (
          <ShopThreadHeader
            dateRangeSelectorProps={{
              startDate,
              endDate,
              currentShopTimezone,
              onReset: handleDateRangeOnReset,
              onDateSelection,
            }}
            filterButtonProps={{
              activeFiltersCount: activeFiltersCount,
              onClick: filterModal.open,
              onReset: handleOnFilterReset,
            }}
            comparisonButtonProps={{
              onClick: handleComparisonOnClick,
              onReset: handleComparisonOnReset,
            }}
            onExportButtonClick={exportModal.open}
            ellipsisItems={mobileEllipsisItems}
          />
        ) : (
          <ThreadHeader
            threadType={activeThreadKind}
            threadTitle={threadTitle}
            onShareInsight={supplierInsightsModal.open}
            isGroupView={isGroupView}
            activeMeasureSlug={measureSlug}
          />
        )}
        {activeThreadKind === THREAD_TYPE.SHOP &&
          decideIfShouldShowAnnouncement(shouldShowAnnouncement, profile) && (
            <div className="mb-4">
              <AnnouncementBar announcement={announcement} />
            </div>
          )}
        {activeThreadKind !== THREAD_TYPE.SHOP && (
          <div
            className={twMerge(
              'px-2 pt-4 md:hidden',

              threadTypeThemeColors.backgroundColor
            )}
          >
            <HeartbeatDateRangeSelector
              startDate={startDate}
              endDate={endDate}
              onReset={handleDateRangeOnReset}
              currentShopTimezone={currentShopTimezone}
              onDateSelection={onDateSelection}
            />
          </div>
        )}
        <MeasuresSliderMenu
          queryVariables={visualizationQueriesBaseVariables}
          comparisonQueryVariable={comparisonQueryVariable}
          activeMeasureSlug={measureSlug}
          setUrlQueryParams={setUrlQueryState}
          activeThreadKind={activeThreadKind}
        />

        <Stack withBottomSpacing>
          <VisualizationPermissionGate
            widget={Visualization.Main}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
            isGroupView={isGroupView}
          >
            <HeartbeatPrimaryChart
              queryVariables={visualizationQueriesBaseVariables}
              comparisonQueryVariable={comparisonQueryVariable}
              comparisonVariable={comparisonVariable}
              currentMeasure={currentMeasure || METRICS[0]}
              setUrlQueryState={setUrlQueryState}
              currentShopTimezone={currentShopTimezone}
              comparisonLabels={comparisonLabels}
              activeScopeTitle={activeShopOrGroup?.title}
            >
              <div className="hidden xl:block">
                <MetricOptionsSelector
                  metricVariants={currentMeasure?.variants || null}
                  onOptionClick={(slug) => {
                    setUrlQueryState({ mVar: slug as MeasureSlug });
                  }}
                  activeVariantSlug={measureVariant || null}
                />
              </div>
              {activeThreadKind !== THREAD_TYPE.SHOP && !isPhone && (
                <>
                  <div className="hidden md:block">
                    <HeartbeatDateRangeSelector
                      startDate={startDate}
                      endDate={endDate}
                      onReset={handleDateRangeOnReset}
                      currentShopTimezone={currentShopTimezone}
                      onDateSelection={onDateSelection}
                    />
                  </div>
                  <ComparisonButton
                    onClick={handleComparisonOnClick}
                    onReset={handleComparisonOnReset}
                  />
                  {isExportable && <ExportButton onClick={exportModal.open} />}
                </>
              )}
            </HeartbeatPrimaryChart>
          </VisualizationPermissionGate>
          {currentMeasure?.variants && (
            <div className="pb-4 xl:hidden">
              <MetricOptionsSelector
                metricVariants={currentMeasure?.variants || null}
                mb-4
                onOptionClick={(slug) => {
                  setUrlQueryState({ mVar: slug as MeasureSlug });
                }}
                activeVariantSlug={measureVariant || measureSlug || null}
              />
            </div>
          )}
          {hasRecommendedTasksFlag && (
            <div className="-mt-8">
              <RecommendedTasks />
            </div>
          )}
        </Stack>
        {isGroupView && (
          <VisualizationPermissionGate
            widget={Visualization.TopShops}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
            isGroupView={isGroupView}
          >
            <TopShopsVisualisation
              queryVariables={visualizationQueriesBaseVariables}
              stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
              activeMeasureUnit={currentMeasure?.unit}
              activeMetricTitle={currentMeasure?.title}
            />
          </VisualizationPermissionGate>
        )}

        {isSupplyApp && activeThreadKind === THREAD_TYPE.SHOP ? (
          <InviteARetailerBanner />
        ) : null}

        {!isSupplyApp && activeThreadKind !== THREAD_TYPE.DEPARTMENT && (
          <VisualizationPermissionGate
            widget={Visualization.TopDepartments}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <HeartbeatTabChart
              title={topDepartmentLabel ?? `Department ${ActiveMeasureTitle}`}
              queryVariables={departmentChartVars}
              unit={currentMeasure?.unit}
              stringifyQueryParams={(departmentId) => {
                return handleStringifyCurrentParamsWithOptionalAdd({
                  departmentId,
                });
              }}
              visualisation={Visualization.TopDepartments}
            />
          </VisualizationPermissionGate>
        )}
        <VisualizationPermissionGate
          widget={Visualization.SellWellWith}
          activeMetric={currentMeasure}
          activeThread={activeThreadKind}
        >
          <SellWellWithWidget
            queryVariables={productChartVars}
            stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
          />
        </VisualizationPermissionGate>

        <section className="mx-auto block max-w-screen-xl space-y-8 empty:hidden md:mt-8 md:flex md:space-y-0 lg:space-x-8">
          <VisualizationPermissionGate
            widget={Visualization.TopProducts}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewProductRef} className="w-full">
              {hasMountedProductOnceBefore && (
                <HorizontalChartLazy
                  title={
                    topProductsLabel ?? `Top Products ${ActiveMeasureTitle}`
                  }
                  queryVariables={productChartVars}
                  unit={currentMeasure?.unit}
                  stringifyQueryParams={(productId) => {
                    return handleStringifyCurrentParamsWithOptionalAdd({
                      productId,
                    });
                  }}
                  visualisation={Visualization.TopProducts}
                />
              )}
            </div>
          </VisualizationPermissionGate>
          <VisualizationPermissionGate
            widget={Visualization.TopCategories}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewCategoryRef} className="w-full">
              {hasMountedCategoryOnceBefore && (
                <HorizontalChartLazy
                  title={
                    topCategoriesLabel ?? `Top Categories ${ActiveMeasureTitle}`
                  }
                  queryVariables={categoryChartVars}
                  unit={currentMeasure?.unit}
                  stringifyQueryParams={(categoryId) => {
                    return handleStringifyCurrentParamsWithOptionalAdd({
                      categoryId,
                    });
                  }}
                  visualisation={Visualization.TopCategories}
                />
              )}
            </div>
          </VisualizationPermissionGate>
        </section>

        {hasListVisualisationFlag ? (
          <VisualizationPermissionGate
            widget={Visualization.Lists}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewListsRef}>
              {hasMountedListBefore && (
                <ListVisualisation
                  queryVariables={visualizationQueriesBaseVariables}
                  activeMeasureUnit={currentMeasure?.unit}
                />
              )}
            </div>
          </VisualizationPermissionGate>
        ) : null}

        {hasProductVariantsVisualisationFlag ? (
          <VisualizationPermissionGate
            widget={Visualization.ProductVariants}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <ProductVariantVisualisation
              queryVariables={visualizationQueriesBaseVariables}
              stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
            />
          </VisualizationPermissionGate>
        ) : null}

        {/* ! MONKEY PATCHING, NEED TO MOVE IN THE VIZ GATE */}
        {!isSupplyApp && (
          <VisualizationPermissionGate
            widget={Visualization.TopSuppliers}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewTopSuppliersRef}>
              {hasMountedTopSuppliersBefore && (
                <SupplierWidget
                  queryVariables={visualizationQueriesBaseVariables}
                  stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
                  activeMeasureIcon={currentMeasure?.icon}
                  activeMetricTitle={currentMeasure?.title}
                />
              )}
            </div>
          </VisualizationPermissionGate>
        )}
        {hasPersonasVisualisationFlag ? (
          <VisualizationPermissionGate
            widget={Visualization.Demographics}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <PersonaVisual
              activeMetricTitle={currentMeasure?.title}
              stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
              activeMeasureIcon={currentMeasure?.icon}
            />
          </VisualizationPermissionGate>
        ) : null}
        {/* ! MONKEY PATCHING, NEED TO MOVE IN THE VIZ GATE */}
        {hasStaffVisualisationFlag && !isSupplyApp ? (
          <VisualizationPermissionGate
            widget={Visualization.Staff}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewStaffVizRef}>
              {hasMountedStaffVizBefore && (
                <StaffVisualisation
                  activeMetricTitle={currentMeasure?.title}
                  stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
                  activeMeasureIcon={currentMeasure?.icon}
                />
              )}
            </div>
          </VisualizationPermissionGate>
        ) : null}
        {hasPostcodeVisualisationFlag ? (
          <VisualizationPermissionGate
            widget={Visualization.Postcodes}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <PostcodeVisualisation
              activeMetricTitle={ActiveMeasureTitle}
              unit={currentMeasure?.unit}
              comparisonTitle={comparisonVariable.key}
              stringifyParams={handleStringifyCurrentParamsWithOptionalAdd}
              comparisonLabels={comparisonLabels}
            />
          </VisualizationPermissionGate>
        ) : null}
        {/* ! MONKEY PATCHING, NEED TO MOVE IN THE VIZ GATE */}
        {!isSupplyApp && (
          <VisualizationPermissionGate
            widget={Visualization.TopCollections}
            activeMetric={currentMeasure}
            activeThread={activeThreadKind}
          >
            <div ref={inViewCollectionsRef}>
              {hasMountedCollectionsOnceBefore && (
                <CollectionsVisualizationLazy
                  queryVariables={{
                    ...visualizationQueriesBaseVariables,
                  }}
                  stringifyQueryParams={(tagId) => {
                    return handleStringifyCurrentParamsWithOptionalAdd({
                      tagId,
                    });
                  }}
                  unit={currentMeasure?.unit}
                  activeMetricTitle={currentMeasure?.title}
                  activeMetricIcon={currentMeasure?.icon ?? null}
                />
              )}
            </div>
          </VisualizationPermissionGate>
        )}
        <VisualizationPermissionGate
          widget={Visualization.TopSpaces}
          activeMetric={currentMeasure}
          activeThread={activeThreadKind}
        >
          <div ref={inViewSpacesRef}>
            {hasMountedSpacesOnceBefore && (
              <HeartbeatTabChart
                title={topSpacesLabel ?? `Top Spaces ${ActiveMeasureTitle}`}
                queryVariables={spaceChartVars}
                unit={currentMeasure?.unit}
                stringifyQueryParams={(spaceId) => {
                  return handleStringifyCurrentParamsWithOptionalAdd({
                    spaceId,
                  });
                }}
                visualisation={Visualization.TopSpaces}
              />
            )}
          </div>
        </VisualizationPermissionGate>
        <VisualizationPermissionGate
          widget={Visualization.BestTimes}
          activeMetric={currentMeasure}
          activeThread={activeThreadKind}
        >
          <div ref={inViewHeatmapRef}>
            {hasMountedHeatmapBefore && (
              <HeatmapChartLazy
                title={betsTimesLabel ?? `Best Times ${ActiveMeasureTitle}`}
                queryVariables={visualizationQueriesBaseVariables}
                unit={currentMeasure?.unit}
                setURLQuery={setUrlQueryState}
              />
            )}
          </div>
        </VisualizationPermissionGate>

        {heartbeatOnboardingSlides.isOpen && (
          <OnboardingCarousel
            isOpen
            hide={handleFinishRetailOnboarding}
            slides={RETAIL_SLIDE_CONTENT}
          />
        )}
        {comparisonModal.isOpen && (
          <ComparisonModal
            modalState={comparisonModal}
            value={comparisonVariable}
            defaultComparison={heartbeatUserDefaultComparison}
            onApply={(comparison) => setUrlQueryState({ comparison })}
            activeMeasureSlug={measureSlug}
            activeThreadKind={activeThreadKind}
            isGroupView={isGroupView}
            mainPeriodStartDayOfWeek={dateTime
              .convertToTimezone(
                startDate,
                currentShopTimezone,
                'YYYY-MM-DDTHH:mm:ssZ'
              )
              .day()}
            currentPeriodDiffInDays={dateTime.diff(startDate, endDate, 'days')}
            currentShopTimezone={currentShopTimezone}
          />
        )}
        {hasMeasureAlertFlag && createAlertModal.isOpen && (
          <AlertCreateModal
            modalState={createAlertModal}
            currentShopId={shopId}
            groupId={groupId}
            activeMeasureSlug={measureSlug}
            threadTitle={threadTitle}
          />
        )}
        {hasMeasureAlertFlag && alertListModal.isOpen && (
          <AlertListModal modalState={alertListModal} />
        )}

        {tasksAppletFlag || hasScannerPermission ? (
          <WidgetBar>
            {hasScannerPermission && (
              <div className="block">
                <WidgetBarButton
                  title="Scan a code"
                  action="scan-code"
                  onPress={() => router.push(ROUTE_PATHS.scanner)}
                  variant="secondary"
                  icon={<BarcodeScannerIcon fillColor="#fff" />}
                />
              </div>
            )}

            {tasksAppletFlag && (
              <WidgetBarButton
                title="Create a new task"
                action="create-new-task"
                onPress={() => router.push(ROUTE_PATHS.createNewTask)}
                variant="primary"
                icon={<PlusIcon light fillColor="currentColor" />}
              />
            )}
          </WidgetBar>
        ) : null}
        {hasTempRetailHeartbeatSupplierInsights &&
          supplierInsightsModal.isOpen && (
            <SupplierInsightsModalLazy
              supplierId={supplierId}
              modalState={supplierInsightsModal}
            />
          )}
        {hasTempHearbeatComprehensiveReportDownload && exportModal.isOpen ? (
          <ExportModal
            activeThread={activeThreadKind}
            queryVariables={visualizationQueriesBaseVariables}
            modalState={exportModal}
          />
        ) : null}
        {filterModal.isOpen ? (
          <FilterModal
            modalState={filterModal}
            filters={activeFilters}
            onFilterSubmit={setActiveFilters}
            handleSetAsDefaultFilters={handleSetFiltersAsDefault}
          />
        ) : null}
      </div>
    </ClientOnly>
  );
};

export default Heartbeat;
